/* eslint-disable no-template-curly-in-string */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import { setLocale } from 'yup';
export const dateLocales: { [key: string]: Locale } = {
  en: enGB,
  de,
};

i18n.use(LanguageDetector).use(initReactI18next);

export const errorsLocalize = {
  en: {
    string: {
      required: 'Required field',
      email: 'Must be a valid email',
      min: 'Must be at least ${min} characters',
      max: 'Must be at most ${max} characters',
    },
    mixed: {
      required: 'Required field',
    },
  },
  de: {
    string: {
      required: 'Erforderliches Feld',
      email: 'Muss eine gültige E-Mail-Adresse enthalten',
      min: 'Muss mindestens ${min} Zeichen lang sein',
      max: 'Darf höchstens ${max} Zeichen lang sein',
    },
    mixed: {
      required: 'Erforderliches Feld',
    },
  },
};

const resources = {
  en: {
    translation: {
      acceptTheOffer: 'Accept the offer',
      fullName: 'Full name',
      signingDate: 'Signing date',
      place: 'Place',
      signature: 'Signature',
      acceptOffer: 'Accept offer',
      decline: 'Decline',
      clear: 'Clear',
      cancel: 'Cancel',
      download: 'Download',
      comment: 'Comment',
      upload: 'Upload',
      signatureHint:
        'Hold the left mouse button and move your mouse to start signing...',
      drawSignatureBelowOr: 'Draw signature below or',
      signatureTooltip:
        'Draw the signature in the field below by holding left mouse button or upload ready-to-use signature from the computer.',
    },
  },
  de: {
    translation: {
      acceptTheOffer: 'Angebot annehmen',
      invoice: 'Rechnung',
      cancellationNote: 'Stornierungshinweis',
      fullName: 'Vollständiger Name',
      signingDate: 'Datum der Unterzeichnung',
      place: 'Ort',
      signature: 'Unterschrift',
      acceptOffer: 'Angebot annehmen',
      decline: 'Ablehnen',
      clear: 'Löschen',
      download: 'Herunterladen',
      cancel: 'Abbrechen',
      comment: 'Kommentar',
    },
  },
};

export const init = async () => {
  await i18n.init({
    resources,
    saveMissing: false,
    fallbackLng: 'en',
    whitelist: ['en', 'de'],
    detection: {
      order: ['localStorage', 'navigator'],
    },
  });
  setLocale(errorsLocalize[i18n.language]);
};

export default i18n;
