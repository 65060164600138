import { init as init18n } from 'i18n';
import React from 'react';

const init = async () => {
  await init18n();
  const App = await import('App');
  const Comp = App.default;
  return (
    <React.StrictMode>
      <Comp />
    </React.StrictMode>
  );
};

export default init;
